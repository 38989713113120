/* eslint-disable no-console */
/* eslint-disable react/self-closing-comp */
import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Bentral from "./Bentral";

const Reservations = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, bentral, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
        <Bentral scriptSrc={bentral} />
      </Row>
    </PageSection>
  );
};

Reservations.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Reservations.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Reservations;

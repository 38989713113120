import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

const ModalContent = ({ buttonTitle, header, content }) => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        {buttonTitle}
      </Button>

      <Modal show={show} onHide={handleClose} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
      </Modal>
    </>
  );
};

ModalContent.propTypes = {
  buttonTitle: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.any,
};

ModalContent.defaultProps = {
  buttonTitle: "",
  header: "",
  content: null,
};

export default ModalContent;


import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery"

import { StaticQuery, graphql } from "gatsby";

const PhotoGallery = ({ section }) => (
  <StaticQuery
    query={graphql`
    {
      allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    `}
    render={(data) => {
      const images = []
      section.forEach(element => {
        const original = data.allFile.edges.find((n) => n.node.relativePath.includes(element.original))
        if (original)
          images.push({
            original: original.node.childImageSharp.gatsbyImageData.images.fallback.src,
            thumbnail: original.node.childImageSharp.gatsbyImageData.images.fallback.src
          })
      });
      if (images.length === 0) {
        return null;
      }
      return <ImageGallery items={images} />;
    }}
  />
);

PhotoGallery.propTypes = {
  section: PropTypes.array.isRequired
};

export default PhotoGallery;

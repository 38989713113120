import React from "react"
import { MapContainer, Popup, TileLayer } from "react-leaflet"
import Marker from "react-leaflet-enhanced-marker"
import "./MapOSM.scss";
import Icon from "./Icon"

const position = [46.272625, 13.953050]

const MapOSM = () => {
  if (typeof window !== 'undefined') {
    return (
      <MapContainer style={{ height: '400px' }} center={position} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          url="https://api.mapbox.com/styles/v1/anzerozman/ckeh8jku70qye19papqtkx9wm/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYW56ZXJvem1hbiIsImEiOiJjajZleGUxNGQyaDUwMnFvMXp1ZXBjejdwIn0.YyWm1_qbwg7JOYaS848lYA"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Marker position={position} icon={<Icon iconName="LocationMarkerIcon" />} >
          <Popup>Gostilnica in apartmaji Štrudl</Popup>
        </Marker>
      </MapContainer>
    )
  }
  return null
}
export default MapOSM 

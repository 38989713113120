import React from "react";
import PropTypes from "prop-types";
import "./Bentral.scss"

const Bentral = ({ scriptSrc }) => {
  const scriptTag = `<script src=${scriptSrc}></script>`
  return (
    <iframe srcDoc={scriptTag} title="bentral" frameBorder={0} height="800px" width="100%" />
  )
}
Bentral.propTypes = {
  scriptSrc: PropTypes.string
};

Bentral.defaultProps = {
  scriptSrc: "",
};
export default Bentral;

import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";
import MapOSM from "components/MapOSM"

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Location = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, address } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={address} description={rootSubHeader} />
      </Row>
      <Row className="text-center" >
        <MapOSM />
      </Row>

    </PageSection >
  );
};

Location.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Location.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Location;

import makeFAIcon from "utils/makeFAIcon";
import makeFAIcon1 from "utils/makeFAIcon1";

import { faShoppingCart, faLaptop, faLock, faUtensils, faBed, faCoffee, faMapMarkerAlt, faFileDownload } from "@fortawesome/free-solid-svg-icons";

export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const Food = makeFAIcon(faUtensils);
export const Bed = makeFAIcon(faBed);
export const Coffe = makeFAIcon(faCoffee);
export const LaptopIcon = makeFAIcon(faLaptop);
export const LockIcon = makeFAIcon(faLock);
export const LocationMarkerIcon = makeFAIcon1(faMapMarkerAlt)
export const FilePdf = makeFAIcon1(faFileDownload)


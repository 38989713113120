import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";
import PhotoGallery from "components/PhotoGallery";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import ModalContent from "components/ModalContent";
import "./Apartment1.scss";

const Apartment1 = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, apartment1, pricelist, pricelistBentral, calendar, calendarBentral, priceListDetails } = frontmatter;

  const pricelistBentralScript = `<script src=${pricelistBentral}></script>`
  const pricelistBentralFrame = <iframe srcDoc={pricelistBentralScript} title="bentralPricelist" frameBorder={0} width="100%" height="600px" />
  const priceListFooter = <p className="pricelist-details">{priceListDetails}</p>
  const priceListContent = <div>{pricelistBentralFrame}{priceListFooter}</div>

  const calendarBentralScript = `<script src=${calendarBentral}></script>`
  const calendarContent = <iframe srcDoc={calendarBentralScript} title="bentralPricelist" frameBorder={0} width="100%" height="400px" />

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="justify-content-md-center">
        <ModalContent buttonTitle={pricelist} content={priceListContent} header={`${pricelist} - ${rootHeader}`} />
      </Row>
      <Row className="justify-content-md-center">
        <ModalContent buttonTitle={calendar} content={calendarContent} header={`${calendar} - ${rootHeader}`} />
      </Row>
      <div><PhotoGallery section={apartment1} /></div>

    </PageSection>
  );
};

Apartment1.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Apartment1.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Apartment1;
